export function SubSellerStatus(stat: string, inside: boolean = false) {
  const status = {
    "ACTIVE": inside ? "Conta ativa" : "Ativo",
    "INACTIVE": "Erro no cadastramento",
    "PROCESSING": "Em processamento",
  }
  return status[stat];
}

export function SubSellerStatusColor(stat: string) {
  const status = {
    "ACTIVE": "#135200",
    "INACTIVE": "#820014",
    "PROCESSING": "#262F40"
  }
  return status[stat];
}

export function SubSellerStatusBg(stat: string) {
  const status = {
    "ACTIVE": "#F6FFED",
    "INACTIVE": "#FFF1F0",
    "PROCESSING": "#EDF1F7"
  }
  return status[stat];
}

export function SubSellerStatusBorder(stat: string) {
  const status = {
    "ACTIVE": "#95DE64",
    "INACTIVE": "#FF7875",
    "PROCESSING": "#A5AEC0"
  }
  return status[stat];
}