export const formatDate = (date: string | Date, shortDate?: boolean) => {
  if (!date) return "";

  if (shortDate) {
    return new Date(
      `${date.toLocaleString().replace(/-/g, "/")} 12:00:00`
    ).toLocaleDateString("pt-BR", {
      timeZone: "America/Sao_Paulo",
      day: "2-digit",
      month: "2-digit",
    });
  }
  return new Date(
    `${date.toLocaleString().replace(/-/g, "/")} 12:00:00`
  ).toLocaleDateString("pt-BR", {
    timeZone: "America/Sao_Paulo",
  });
};
