interface IFormatCurreny {
  value: number;
  locale?: string | string[];
  style?: string;
}
export const formatCurrency = ({
  locale = "pt-BR",
  value,
  style = "currency",
}: IFormatCurreny) => {
  if (typeof value !== "number") {
    parseFloat(value);
  }

  if (style) {
    return new Intl.NumberFormat(locale, {
      style,
      currency: "BRL",
    }).format(value ? value : 0);
  }
  return new Intl.NumberFormat(locale).format(value ? value : 0);
};
